import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import { dom } from 'react-redux-app/lib/modules/core/utils';

import config from '../../../../../config';


export default (
  initialState,
  customMiddlewares,
  rootReducer,
  sagas,
  apiClient,
  apiClientHelper = null
) => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    thunk.withExtraArgument({ apiClient, apiClientHelper }),
    ...customMiddlewares,
    sagaMiddleware,
  ];

  let enhancer = applyMiddleware(...middlewares);
  /* eslint-disable no-underscore-dangle */
  if (process.env.NODE_ENV === 'development' && dom.isBrowser() && config.isDevToolsEnabled) {
    const devEnhancer = applyMiddleware(...middlewares, createLogger({ diff: true }));
    const reduxExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    enhancer = reduxExtension ? compose(devEnhancer, reduxExtension()) : devEnhancer;
  }
  /* eslint-enable no-underscore-dangle */

  const store = createStore(rootReducer, initialState, enhancer);

  if (apiClientHelper) {
    apiClientHelper.setReduxStore(store);
  }

  if (dom.isBrowser() && sagas) {
    sagaMiddleware.run(sagas);
  }

  return store;
};
