import { dom, format } from 'react-redux-app/lib/modules/core/utils';

import ApiClient, { apiClient } from '../../../../modules/core/api/ApiClient';


const errorParamsFormatterMap = {
  maxFileSize: format.humanReadableSize,
  allowedMediaTypes: format.humanReadableFileTypes,
};

class ApiClientHelper {
  constructor(apiClientInstance) {
    this.reduxStore = null;
    // Note: a hack [Issue #23847]
    this.isWindowUnloading = false;

    if (dom.isBrowser()) {
      window.addEventListener(
        'beforeunload',
        () => {
          this.isWindowUnloading = true;
        }
      );
    }

    ApiClient.getMethods().forEach(method => {
      this[method] = (path, ...rest) => {
        if (this.isWindowUnloading) {
          return new Promise(() => {});
        }

        return new Promise((resolve, reject) => {
          apiClientInstance[method](path, ...rest)
            .then(
              result => {
                if (this.isWindowUnloading) {
                  return;
                }

                resolve(result);
              },
              error => {
                if (this.isWindowUnloading) {
                  return;
                }

                reject(ApiClientHelper.formatRequestErrorParams(error));
              }
            );
        });
      };
    });
  }

  setReduxStore(reduxStore) {
    this.reduxStore = reduxStore;
  }

  static formatRequestErrorParams({ globalErrors, fieldErrors }) {
    const result = {};

    if (globalErrors) {
      result.globalErrors = ApiClientHelper.formatErrorParams(globalErrors);
    }
    if (fieldErrors) {
      result.fieldErrors = ApiClientHelper.formatErrorParams(fieldErrors);
    }

    return result;
  }

  static formatErrorParams(errors) {
    return errors.map(error => {
      const { params } = error;
      if (!params) {
        return error;
      }

      const formattedParams = {};
      Object.keys(params).forEach(paramName => {
        formattedParams[paramName] = typeof errorParamsFormatterMap[paramName] === 'function'
          ? errorParamsFormatterMap[paramName](params[paramName])
          : params[paramName];
      });

      return { ...error, params: formattedParams };
    });
  }
}

export default ApiClientHelper;

export const apiClientHelper = new ApiClientHelper(apiClient);
