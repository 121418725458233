import { all } from 'redux-saga/effects';

import config from '../../../../../config';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import getWatchExceptions from '../../core/exception/saga';


export default function* saga() {
  yield all([
    getWatchExceptions(INTERNAL_ERROR_URI, config.isDevToolsEnabled)(),
  ]);
}
