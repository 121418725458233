import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__SITE_PREVIEW } from '../../constants/dialogs';


export const actionShowSitePreviewDialog = img => (
  actionShowModal(MODAL_DIALOG__SITE_PREVIEW, { img })
);

export const actionHideSitePreviewDialog = () => (
  actionHideModal(MODAL_DIALOG__SITE_PREVIEW)
);
