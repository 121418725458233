import { RRA_ACTION_LINK } from 'react-redux-app/lib/modules/actionLink/constants';
import { RRA_ALERT } from 'react-redux-app/lib/modules/alert/constants';
import { RRA_CONTEXT_MENU_LINK } from 'react-redux-app/lib/modules/contextMenuLink/constants';
import { RRA_BUTTON } from 'react-redux-app/lib/modules/button/constants';
import { RRA_BUTTON_SET } from 'react-redux-app/lib/modules/buttonSet/constants';

import {
  RRA_FORM_AUTOCOMPLETE,
  RRA_FORM_CHECKBOX,
  RRA_FORM_DAY_MONTH_YEAR,
  RRA_FORM_ERROR,
  RRA_FORM_FIELD,
  RRA_FORM_INPUT,
  RRA_FORM_INPUT_TEXT_WITH_ADDON,
  RRA_FORM_INPUT_PASSWORD,
  RRA_FORM_MULTI_SELECT,
  RRA_FORM_TEXTAREA,
  RRA_FORM_RADIO_BUTTON,
} from 'react-redux-app/lib/modules/form/constants';
//
import { RRA_HEADING } from 'react-redux-app/lib/modules/heading/constants';
import { RRA_LINK } from 'react-redux-app/lib/modules/link/constants';

import { RRA_MODAL, RRA_MODALS_STACK } from 'react-redux-app/lib/modules/modal/constants';

import actionLinkTheme from '../../styles/libs/react-redux-app/ActionLink/index.css.json';
import buttonTheme from '../../styles/libs/react-redux-app/Button/index.css.json';
import buttonSetTheme from '../../styles/libs/react-redux-app/ButtonSet/index.css.json';
import headingTheme from '../../styles/libs/react-redux-app/Heading/index.css.json';
import linkTheme from '../../styles/libs/react-redux-app/Link/index.css.json';

import modalTheme from '../../styles/libs/react-redux-app/modal/Modal/index.css.json';
import modalsStackTheme from '../../styles/libs/react-redux-app/modal/ModalsStack/index.css.json';

import alertTheme from '../../styles/libs/react-redux-app/Alert/index.css.json';
import contextMenuTheme from '../../styles/libs/react-redux-app/ContextMenuLink/index.css.json';

import autocompleteTheme from '../../styles/libs/react-redux-app/form/Autocomplete/index.css.json';
import checkboxTheme from '../../styles/libs/react-redux-app/form/Checkbox/index.css.json';
import dayMonthYearTheme from '../../styles/libs/react-redux-app/form/DayMonthYear/index.css.json';
import fieldTheme from '../../styles/libs/react-redux-app/form/Field/index.css.json';
import formErrorTheme from '../../styles/libs/react-redux-app/form/FormError/index.css.json';
import inputTheme from '../../styles/libs/react-redux-app/form/Input/index.css.json';
import inputTextWithAddonTheme from '../../styles/libs/react-redux-app/form/InputTextWithAddon/index.css.json';
import inputPasswordTheme from '../../styles/libs/react-redux-app/form/InputPassword/index.css.json';
import multiSelectTheme from '../../styles/libs/react-redux-app/form/MultiSelect/index.css.json';
import radioButtonTheme from '../../styles/libs/react-redux-app/form/RadioButton/index.css.json';
import textareaTheme from '../../styles/libs/react-redux-app/form/Textarea/index.css.json';

const contextTheme = {
  [RRA_ACTION_LINK]: actionLinkTheme,
  [RRA_ALERT]: alertTheme,
  [RRA_BUTTON]: buttonTheme,
  [RRA_BUTTON_SET]: buttonSetTheme,
  [RRA_CONTEXT_MENU_LINK]: contextMenuTheme,

  [RRA_FORM_AUTOCOMPLETE]: autocompleteTheme,
  [RRA_FORM_CHECKBOX]: checkboxTheme,
  [RRA_FORM_DAY_MONTH_YEAR]: dayMonthYearTheme,
  [RRA_FORM_ERROR]: formErrorTheme,
  [RRA_FORM_FIELD]: fieldTheme,
  [RRA_FORM_INPUT]: inputTheme,
  [RRA_FORM_INPUT_TEXT_WITH_ADDON]: inputTextWithAddonTheme,
  [RRA_FORM_INPUT_PASSWORD]: inputPasswordTheme,
  [RRA_FORM_MULTI_SELECT]: multiSelectTheme,
  [RRA_FORM_RADIO_BUTTON]: radioButtonTheme,
  [RRA_FORM_TEXTAREA]: textareaTheme,

  [RRA_HEADING]: headingTheme,
  [RRA_LINK]: linkTheme,
  [RRA_MODAL]: modalTheme,
  [RRA_MODALS_STACK]: modalsStackTheme,
};

export default contextTheme;
