let config = {};

const productionConfig = require('./config.production.json');

try {
  /* eslint-disable global-require */
  const overrideConfig = require('./config.override.json');
  config = Object.assign({}, productionConfig, overrideConfig);
} catch (ex) {
  config = productionConfig;
}

const finalConfig = config;

export default finalConfig;
