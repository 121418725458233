import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__RESET_PASSWORD } from '../../constants/dialogs';


export const actionShowResetPasswordDialog = token => (
  actionShowModal(MODAL_DIALOG__RESET_PASSWORD, { token })
);

export const actionHideResetPasswordDialog = () => (
  actionHideModal(MODAL_DIALOG__RESET_PASSWORD)
);
