import _get from 'lodash/get';


export const getResetPasswordError = state => (
  _get(state, 'password.errors.resetPasswordError')
);
export const getRestorePasswordError = state => (
  _get(state, 'password.errors.restorePasswordError')
);
export const getValidateTokenError = state => (
  _get(state, 'password.errors.validateTokenError')
);
