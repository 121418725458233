import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__MOBILE_MENU } from '../../constants/dialogs';


export const actionShowMobileMenuModalDialog = (pageId, isLogged) => (
  actionShowModal(MODAL_DIALOG__MOBILE_MENU, { pageId, isLogged })
);
export const actionHideMobileMenuModalDialog = () => (
  actionHideModal(MODAL_DIALOG__MOBILE_MENU)
);
