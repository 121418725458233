import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__TEMPLATE_PREVIEW } from '../../constants/dialogs';


export const actionShowTemplatePreviewDialog = img => (
  actionShowModal(MODAL_DIALOG__TEMPLATE_PREVIEW, { img })
);

export const actionHideTemplatePreviewDialog = () => (
  actionHideModal(MODAL_DIALOG__TEMPLATE_PREVIEW)
);
