import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import loadable from '@loadable/component';

import { ThemeProvider } from 'react-redux-app/lib/modules/core/components';
import themeContext from '../../../themeContext';

import ModalsStack from 'react-redux-app/lib/modules/modal/containers/ModalsStack';

import { actionShowLoginDialog } from '../../auth/actions/dialogs/loginDialog';
import { actionShowRegistrationDialog } from '../../auth/actions/dialogs/registrationDialog';
import { actionShowContactUsDialog } from '../../../../modules/contactUs/actions/dialogs/contactUsDialog';
import { actionShowMobileMenuModalDialog } from '../actions/dialogs/mobileMenuModalDialog';
import { actionShowRestorePasswordDialog } from '../../password/actions/dialogs/restorePasswordDialog';
import { actionShowSitePreviewDialog } from '../../sites/actions/dialogs/sitePreviewDialog';
import { actionShowTemplatePreviewDialog } from '../../templatePreiview/actions/dialogs/templatePreviewDialog';

import modalNameToComponentMap from '../modalNameToComponentMap';

import { actionChangeLang } from '../../../../modules/core/i18n/actions';

import { getTranslationsForCurrentLang, getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { availableLangs } from '../../../../modules/core/i18n/constants';

import { externalCommandConfig } from '../externalCommandConfig';
import externalCommandHandlerHOC from '../externalCommand/containers/externalCommandHandlerHOC';


const LoginPage = loadable(() => import('../../auth/containers/LoginPage'));
const RegistrationPage = loadable(() => import('../../auth/containers/RegistrationPage'));

// Note: IE doesn't have a forEach method for HTMLCollection objects
const htmlCollectionForeach = (collection, callback) => {
  if (collection && collection.length > 0) {
    for (let i = 0; i < collection.length; ++i) {
      callback(collection[i], i);
    }
  }
};

const ExternalCommandHandler = externalCommandHandlerHOC(externalCommandConfig);

const propTypes = {
  lang: PropTypes.oneOf(availableLangs).isRequired,
  messages: PropTypes.objectOf(PropTypes.string).isRequired,

  // Actions
  actionChangeLang: PropTypes.func.isRequired,
  actionShowLoginDialog: PropTypes.func.isRequired,
  actionShowRegistrationDialog: PropTypes.func.isRequired,
  actionShowContactUsDialog: PropTypes.func.isRequired,
  actionShowMobileMenuModalDialog: PropTypes.func.isRequired,
  actionShowRestorePasswordDialog: PropTypes.func.isRequired,
  actionShowSitePreviewDialog: PropTypes.func.isRequired,
  actionShowTemplatePreviewDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  lang: getCurrentLang(state),
  messages: getTranslationsForCurrentLang(state),
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    actionChangeLang,
    actionShowLoginDialog,
    actionShowRegistrationDialog,
    actionShowContactUsDialog,
    actionShowRestorePasswordDialog,
    actionShowMobileMenuModalDialog,
    actionShowSitePreviewDialog,
    actionShowTemplatePreviewDialog,
  }, dispatch)
);

const RegistrationPagePortal = () => {
  const registrationPageContainers = document.getElementsByClassName('registrationPageContainer');
  if (!registrationPageContainers || registrationPageContainers.length === 0) {
    return null;
  }

  return ReactDOM.createPortal(
    <RegistrationPage />,
    registrationPageContainers[0]
  );
};

const LoginPagePortal = () => {
  const loginPageContainers = document.getElementsByClassName('loginPageContainer');
  if (!loginPageContainers || loginPageContainers.length === 0) {
    return null;
  }

  return ReactDOM.createPortal(
    <LoginPage />,
    loginPageContainers[0]
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.showLoginDialog = this.showLoginDialog.bind(this);
    this.showContactUsDialog = this.showContactUsDialog.bind(this);
    this.showRestorePasswordDialog = this.showRestorePasswordDialog.bind(this);
    this.showMobileMenuDialog = this.showMobileMenuDialog.bind(this);
    this.showRegistrationDialog = this.showRegistrationDialog.bind(this);
    this.showSitePreviewDialog = this.showSitePreviewDialog.bind(this);
    this.showTemplatePreviewDialog = this.showTemplatePreviewDialog.bind(this);
  }

  componentDidMount() {
    // Note: to set cookies, cuz the lang is set without setting cookies in the configureStore()
    this.props.actionChangeLang(this.props.lang);

    const loginLink = document.getElementById('loginLink');
    if (loginLink) {
      loginLink.addEventListener('click', this.showLoginDialog);
    }

    const registrationButtons = document.getElementsByName('registrationButton');
    htmlCollectionForeach(registrationButtons, registrationButton => {
      registrationButton.addEventListener('click', this.showRegistrationDialog);
    });

    const contactUsLinks = document.getElementsByName('contactUsLink');
    htmlCollectionForeach(contactUsLinks, contactUsLink => {
      contactUsLink.addEventListener('click', this.showContactUsDialog);
    });

    const restorePasswordLink = document.getElementById('restorePasswordLink');
    if (restorePasswordLink) {
      restorePasswordLink.addEventListener('click', this.showRestorePasswordDialog);
    }

    const mobileMenuLink = document.getElementById('mobileMenuLink');
    if (mobileMenuLink) {
      mobileMenuLink.addEventListener('click', this.showMobileMenuDialog);
    }

    const sitesPreviewImgs = document.getElementsByClassName('sitePreview__img');
    htmlCollectionForeach(sitesPreviewImgs, sitesPreviewImg => {
      sitesPreviewImg.addEventListener('click', this.showSitePreviewDialog);
    });

    const templatePreviewImgs = document.getElementsByClassName('templatePreview__image');
    htmlCollectionForeach(templatePreviewImgs, templatePreviewImg => {
      templatePreviewImg.addEventListener('click', this.showTemplatePreviewDialog);
    });
  }

  showLoginDialog(e) {
    e.preventDefault();
    this.props.actionShowLoginDialog();
  }

  showRegistrationDialog(e) {
    e.preventDefault();
    this.props.actionShowRegistrationDialog();
  }

  showRestorePasswordDialog(e) {
    e.preventDefault();
    this.props.actionShowRestorePasswordDialog();
  }

  showContactUsDialog(e) {
    e.preventDefault();
    this.props.actionShowContactUsDialog();
  }

  showMobileMenuDialog(e) {
    e.preventDefault();
    const pageId = document.getElementById('pageId');
    const isLogged = document.getElementById('isLogged');
    this.props.actionShowMobileMenuModalDialog(pageId.innerHTML, isLogged.innerHTML === 'true');
  }

  showSitePreviewDialog(e) {
    e.preventDefault();
    this.props.actionShowSitePreviewDialog(e.target.getAttribute('data-img'));
  }

  showTemplatePreviewDialog(e) {
    e.preventDefault();
    this.props.actionShowTemplatePreviewDialog(e.target.getAttribute('data-img'));
  }

  render() {
    const { messages, lang } = this.props;

    return (
      <IntlProvider
        locale={lang}
        messages={messages}
      >
        <ThemeProvider theme={themeContext}>
          <div>
            <LoginPagePortal />
            <RegistrationPagePortal />
            <ExternalCommandHandler />
            <ModalsStack modalNameToComponentMap={modalNameToComponentMap} />
          </div>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

App.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(App);
