import { combineReducers } from 'redux';

import {
  ACTION_INIT_EXCHANGE_RATES_FOR_EUR__REQUEST,
  ACTION_INIT_EXCHANGE_RATES_FOR_EUR__SUCCESS,
  ACTION_INIT_EXCHANGE_RATES_FOR_EUR__FAIL,
} from '../actionTypes/exchangeRatesForEur';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_EXCHANGE_RATES_FOR_EUR__SUCCESS:
      return action.payload.result;

    case ACTION_INIT_EXCHANGE_RATES_FOR_EUR__FAIL:
      return null;

    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_EXCHANGE_RATES_FOR_EUR__REQUEST:
    case ACTION_INIT_EXCHANGE_RATES_FOR_EUR__SUCCESS:
      return null;

    case ACTION_INIT_EXCHANGE_RATES_FOR_EUR__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  error,
});
