import { combineReducers } from 'redux';

import exception from 'react-redux-app/lib/modules/exception/reducer';
import globalError from 'react-redux-app/lib/modules/globalError/reducer';
import i18n from 'react-redux-app/lib/modules/i18n/reducer';
import modals from 'react-redux-app/lib/modules/modal/reducer';

import auth from '../../auth/reducer';
import contactUs from '../../../../modules/contactUs/reducer';
import init from '../../../../modules/init/reducer';
import password from '../../password/reducer';

export default combineReducers({
  // vendor
  exception,
  globalError,
  i18n,
  modals,

  // rivcash
  auth,
  contactUs,
  init,
  password,
});
