import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__RESTORE_PASSWORD } from '../../constants/dialogs';


export const actionShowRestorePasswordDialog = () => (
  actionShowModal(MODAL_DIALOG__RESTORE_PASSWORD)
);

export const actionHideRestorePasswordDialog = () => (
  actionHideModal(MODAL_DIALOG__RESTORE_PASSWORD)
);
