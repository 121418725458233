import superagent from 'superagent';
import { normalize } from 'normalizr';
import { httpStatusCodes } from 'react-redux-app/lib/modules/core/constants';

import * as errors from './constants/errors';


const getErrorsByResponse = (err, res) => {
  const getDefaultErrorCodeByStatusCode = httpStatusCode => {
    switch (httpStatusCode) {
      case httpStatusCodes.FORBIDDEN:
        return res.body.error === 'UNAUTHORIZED'
          ? errors.API_UNAUTHORIZED_ERROR
          : errors.API_FORBIDDEN_ERROR;

      case httpStatusCodes.BAD_REQUEST:
        return errors.API_BAD_REQUEST_ERROR;

        // case httpStatusCodes.NOT_FOUND:
        //  return apiErrors.API_NOT_FOUND_ERROR;

      default:
        return errors.API_UNKNOWN_ERROR;
    }
  };

  const httpStatusCode = err.status || 500;
  let result = null;
  if (httpStatusCode === httpStatusCodes.BAD_REQUEST && res && res.body) {
    const { globalErrors, fieldErrors } = res.body;
    if ((fieldErrors && fieldErrors.length > 0) || (globalErrors && globalErrors.length > 0)) {
      result = {
        ...res.body,
      };

      result.fieldErrors = fieldErrors || [];
      result.globalErrors = globalErrors || [];
    }
  }

  if (!result) {
    result = {
      globalErrors: [{ code: getDefaultErrorCodeByStatusCode(httpStatusCode) }],
      fieldErrors: [],
    };
  }

  return result;
};

export default class ApiClient {
  constructor(/* nodeRequest = null */) {
    ApiClient.getMethods().forEach(method => {
      this[method] = (
        path,
        {
          params,
          data,
          headers = {},
          file = false,
          onProgress = null,
          blobResponse = false,
        } = {},
        schema,
        schemaOptions
      ) => (
        new Promise((resolve, reject) => {
          const request = superagent[method](path);

          const defaultHeaders = { Accept: 'application/json' };
          if (!file) {
            defaultHeaders['Content-Type'] = 'application/json';
          }

          // Note: disables IE caching
          if (method === 'get') {
            defaultHeaders['If-Modified-Since'] = 0;
          }

          request.set({ ...defaultHeaders, ...headers });

          if (onProgress) {
            request.on('progress', onProgress);
          }

          if (params) {
            request.query(params);
          }

          // if (nodeRequest && nodeRequest.get('cookie')) {
          //   request.set('cookie', nodeRequest.get('cookie'));
          // }

          if (data) {
            request.send(data);
          }

          if (blobResponse) {
            request.responseType('blob');
          }

          request.end(
            (err, res) => {
              if (err) {
                reject(getErrorsByResponse(err, res));
              } else {
                let result = res.body || res.blob;
                if (schema && result) {
                  result = normalize(result, schema, schemaOptions);
                }
                resolve(result);
              }
            }
          );
        })
      );
    });
  }

  static getMethods() {
    return ['get', 'post', 'put', 'del'];
  }
}

export const apiClient = new ApiClient();
