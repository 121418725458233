import { availableLangs } from '../constants';


export const getLangFromRequestUri = requestUri => {
  const re = new RegExp(`^/(${availableLangs.join('|')})(/.*)?$`);
  const matches = requestUri.match(re);
  return matches?.[1] || null;
};

export const changeLangForRequestUri = (requestUri, lang) => {
  const currentLang = getLangFromRequestUri(requestUri);
  if (currentLang) {
    const re = new RegExp(`^/(${availableLangs.join('|')})`);
    return requestUri.replace(re, `/${lang}`);
  }

  return `/${lang}${requestUri}`;
};
