import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__SOCIAL_REGISTRATION } from '../../constants/dialogs';


export const actionShowSocialRegistrationDialog = (email, socialNetworkToken) => (
  actionShowModal(MODAL_DIALOG__SOCIAL_REGISTRATION, { email, socialNetworkToken })
);

export const actionHideSocialRegistrationDialog = () => (
  actionHideModal(MODAL_DIALOG__SOCIAL_REGISTRATION)
);
