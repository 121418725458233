import { dom } from 'react-redux-app/lib/modules/core/utils';
import { getIndexUrl } from '../../app/utils/siteUrls';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import {
  SOCIAL_NETWORK_AUTH_FAILED,
  SOCIAL_NETWORK_ALREADY_ASSIGNED_SUCH_TYPE,
  SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_USER,
  SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_ANOTHER_USER,
  SOCIAL_NETWORK_NEED_EXTRA_DATA,
  SOCIAL_NETWORK_SHOULD_ASSIGN_AFTER_LOGIN,
} from '../constants/errors';

import { actionAddGlobalError } from 'react-redux-app/lib/modules/globalError/actions';
import { actionShowSocialRegistrationDialog } from './dialogs/socialRegistrationDialog';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';


export const paramsNames = ['error'];

export const actionHandleSocialAuthErrorOnExternalCommand = ({ error }) => (
  (dispatch, getState) => {
    const socialNetworkAuthError = JSON.parse(error);

    const addGlobalError = textLangKey => {
      dispatch(actionAddGlobalError({
        text: { id: textLangKey },
        header: { id: 'header.registration' },
      }));
    };

    switch (socialNetworkAuthError.code) {
      case SOCIAL_NETWORK_AUTH_FAILED:
        dom.redirect(getIndexUrl(getCurrentLang(getState())));
        break;

      case SOCIAL_NETWORK_ALREADY_ASSIGNED_SUCH_TYPE:
        addGlobalError('error.social.already-assigned-such-type');
        break;

      case SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_USER:
        addGlobalError('error.social.already-assigned-to-user');
        break;

      case SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_ANOTHER_USER:
        addGlobalError('error.social.assigned-to-another-user');
        break;

      case SOCIAL_NETWORK_SHOULD_ASSIGN_AFTER_LOGIN:
        addGlobalError('error.social.duplicate-email');
        break;

      case SOCIAL_NETWORK_NEED_EXTRA_DATA: {
        const { email, token } = socialNetworkAuthError.params;

        dispatch(actionShowSocialRegistrationDialog(email, token));
        break;
      }

      default:
        dom.redirect(INTERNAL_ERROR_URI);
        break;
    }
  }
);
