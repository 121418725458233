import { dom } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';

import { getValidateTokenError } from '../selectors/errors';

import { actionValidateTokenRequest } from './validateToken';
import { actionShowResetPasswordDialog } from './dialogs/resetPasswordDialog';


export const paramsNames = ['token'];

export const actionValidateTokenOnExternalCommand = ({ token }) => (
  (dispatch, getState) => (
    dispatch(
      actionValidateTokenRequest(token)
    ).then(
      () => {
        const error = getValidateTokenError(getState());
        if (error && maybeHandleCriticalError(null, error.globalErrors, dom.redirect)) {
          return;
        }

        dispatch(actionShowResetPasswordDialog(token));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    })
  )
);
