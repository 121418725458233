import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';

import {
  ACTION_VALIDATE_TOKEN__REQUEST,
  ACTION_VALIDATE_TOKEN__SUCCESS,
  ACTION_VALIDATE_TOKEN__FAIL,
} from '../actionTypes';


const actionValidateTokenSuccess = () => ({
  type: ACTION_VALIDATE_TOKEN__SUCCESS,
});

const actionValidateTokenFail = error => ({
  type: ACTION_VALIDATE_TOKEN__FAIL,
  payload: { error },
});

export const actionValidateTokenRequest = token => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_VALIDATE_TOKEN__REQUEST,
    });

    return apiClientHelper.post(
      '/rest/webmaster/login/restore_password/check_token',
      { params: { token } }
    ).then(
      () => {
        dispatch(actionValidateTokenSuccess());
      },
      error => {
        dispatch(actionValidateTokenFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
