export const ACTION_LOGIN_BY_NICK__REQUEST = 'ACTION_LOGIN_BY_NICK__REQUEST';
export const ACTION_LOGIN_BY_NICK__SUCCESS = 'ACTION_LOGIN_BY_NICK__SUCCESS';
export const ACTION_LOGIN_BY_NICK__FAIL = 'ACTION_LOGIN_BY_NICK__FAIL';

export const ACTION_REGISTER__REQUEST = 'ACTION_REGISTER__REQUEST';
export const ACTION_REGISTER__SUCCESS = 'ACTION_REGISTER__SUCCESS';
export const ACTION_REGISTER__FAIL = 'ACTION_REGISTER__FAIL';

export const ACTION_LOGIN_BY_GOOGLE__REQUEST = 'ACTION_LOGIN_BY_GOOGLE__REQUEST';
export const ACTION_LOGIN_BY_TWITTER__REQUEST = 'ACTION_LOGIN_BY_TWITTER__REQUEST';
