import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__LOGIN } from '../../constants/dialogs';


export const actionShowLoginDialog = (error, redirectUrl) => (
  actionShowModal(MODAL_DIALOG__LOGIN, { error, redirectUrl })
);

export const actionHideLoginDialog = () => (
  actionHideModal(MODAL_DIALOG__LOGIN)
);
