// TODO it's got from rivcash cabinet. It's need to discuss what we will do in errors cases.

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import {
  API_BAD_REQUEST_ERROR,
  API_NOT_FOUND_ERROR,
  API_UNKNOWN_ERROR,
  API_BAD_REQUEST_BODY_ERROR,
  API_OPERATION_FAILED_ERROR,
} from '../../../modules/core/api/constants/errors';


const getApiCriticalErrorHandlers = reduxStore => (location, redirectFunction) => ({
  [API_BAD_REQUEST_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
  [API_NOT_FOUND_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
  [API_UNKNOWN_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
  [API_BAD_REQUEST_BODY_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
  [API_OPERATION_FAILED_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
});

export default getApiCriticalErrorHandlers;
