import { combineReducers } from 'redux';

import loginByNickError from './loginByNickError';
import registerError from './registerError';


export default combineReducers({
  loginByNickError,
  registerError,
});
