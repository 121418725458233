import {
  actionHandleSocialAuthErrorOnExternalCommand,
  paramsNames as socialRegistrationParamsNames,
} from '../auth/actions/externalCommandShowSocialRegistrationDialog';
import {
  actionValidateTokenOnExternalCommand,
  paramsNames as restorePasswordParamsNames,
} from '../password/actions/externalCommandValidateToken';

import {
  actionShowRegistrationDialog,
} from '../auth/actions/dialogs/registrationDialog';
import {
  actionShowRestorePasswordDialog,
} from '../password/actions/dialogs/restorePasswordDialog';


export const externalCommandConfig = {
  socialNetworkAuthError: {
    action: actionHandleSocialAuthErrorOnExternalCommand,
    paramsNames: socialRegistrationParamsNames,
  },
  restorePassword: {
    action: actionValidateTokenOnExternalCommand,
    paramsNames: restorePasswordParamsNames,
  },
  showRegistration: {
    action: actionShowRegistrationDialog,
    paramsNames: [],
  },
  showRestorePassword: {
    action: actionShowRestorePasswordDialog,
    paramsNames: [],
  },
};
