import loadable from '@loadable/component';

import { MODAL_DIALOG__ERROR } from 'react-redux-app/lib/modules/globalErrorDialog/constants/dialogs';

import { MODAL_DIALOG__MOBILE_MENU } from './constants/dialogs';
import {
  MODAL_DIALOG__LOGIN,
  MODAL_DIALOG__REGISTRATION,
  MODAL_DIALOG__SOCIAL_REGISTRATION,
} from '../auth/constants/dialogs';
import { MODAL_DIALOG__CONTACT_US } from '../../../modules/contactUs/constants/dialogs';
import {
  MODAL_DIALOG__RESTORE_PASSWORD,
  MODAL_DIALOG__RESET_PASSWORD,
} from '../password/constants/dialogs';
import { MODAL_DIALOG__SITE_PREVIEW } from '../sites/constants/dialogs';
import { MODAL_DIALOG__TEMPLATE_PREVIEW } from '../templatePreiview/constants/dialogs';


const GlobalErrorDialog = loadable(() => import('../../../modules/core/globalErrorDialog/components/GlobalErrorDialog'));

const MobileMenuModalDialog = loadable(() => import('./containers/dialogs/MobileMenuModalDialog'));
const LoginDialog = loadable(() => import('../auth/containers/dialogs/LoginDialog'));
const RegistrationDialog = loadable(() => import('../auth/containers/dialogs/RegistrationDialog'));
const SocialRegistrationDialog = loadable(() => import('../auth/containers/dialogs/SocialRegistrationDialog'));
const ContactUsDialog = loadable(() => import('../../../modules/contactUs/containers/dialogs/ContactUsDialog'));
const RestorePasswordDialog = loadable(() => import('../password/containers/dialogs/RestorePasswordDialog'));
const ResetPasswordDialog = loadable(() => import('../password/containers/dialogs/ResetPasswordDialog'));
const SitePreviewDialog = loadable(() => import('../sites/containers/dialogs/SitePreviewDialog'));
const TemplatePreviewDialog = loadable(() => import('../templatePreiview/containers/dialogs/TemplatePreviewDialog'));

export default {
  [MODAL_DIALOG__ERROR]: GlobalErrorDialog,

  [MODAL_DIALOG__MOBILE_MENU]: MobileMenuModalDialog,
  [MODAL_DIALOG__LOGIN]: LoginDialog,
  [MODAL_DIALOG__REGISTRATION]: RegistrationDialog,
  [MODAL_DIALOG__SOCIAL_REGISTRATION]: SocialRegistrationDialog,
  [MODAL_DIALOG__CONTACT_US]: ContactUsDialog,
  [MODAL_DIALOG__RESTORE_PASSWORD]: RestorePasswordDialog,
  [MODAL_DIALOG__RESET_PASSWORD]: ResetPasswordDialog,
  [MODAL_DIALOG__SITE_PREVIEW]: SitePreviewDialog,
  [MODAL_DIALOG__TEMPLATE_PREVIEW]: TemplatePreviewDialog,
};
