export const BAD_CREDENTIALS = 'BAD_CREDENTIALS';

export const SOCIAL_NETWORK_AUTH_FAILED = 'SOCIAL_NETWORK_AUTH_FAILED';
export const SOCIAL_NETWORK_ALREADY_ASSIGNED_SUCH_TYPE = 'SOCIAL_NETWORK_ALREADY_ASSIGNED_SUCH_TYPE';
export const SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_USER = 'SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_USER';
export const SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_ANOTHER_USER = 'SOCIAL_NETWORK_ALREADY_ASSIGNED_TO_ANOTHER_USER';
export const SOCIAL_NETWORK_NEED_EXTRA_DATA = 'SOCIAL_NETWORK_NEED_EXTRA_DATA';
export const SOCIAL_NETWORK_SHOULD_ASSIGN_AFTER_LOGIN = 'SOCIAL_NETWORK_SHOULD_ASSIGN_AFTER_LOGIN';

export const NOT_FOUND = 'NOT_FOUND';
