import { LANG_IT, LANG_EN } from '../../lang/constants';


export const LANGUAGE_ENGLISH = 'English';
export const LANGUAGE_ITALIAN = 'Italiano';

export const LANGUAGE_COOKIE = 'lang';
export const COOKIE_LANGUAGE_EXPIRES = 1000 * 60 * 60 * 24 * 365;

export const availableLangs = [
  LANG_IT, LANG_EN,
];

export const DEFAULT_LANG = LANG_IT;
