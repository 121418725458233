import { combineReducers } from 'redux';

import exchangeRatesForEur from './exchangeRatesForEur';
import initData from './initData';


export default combineReducers({
  exchangeRatesForEur,
  initData,
});
