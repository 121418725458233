import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import { url } from 'react-redux-app/lib/modules/routing/utils';


const bindActionCreator = (actionCreator, dispatch) => (...args) => (
  dispatch(actionCreator(...args))
);

const PARAM_NAME_EXTERNAL_COMMAND = 'externalCommand';

export default externalCommandConfig => {
  const propTypes = {
    boundExternalCommandConfig: PropTypes.objectOf(PropTypes.shape({
      action: PropTypes.func.isRequired,
      paramsNames: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
  };

  const mapDispatchToProps = dispatch => {
    const boundExternalCommandConfig = {};
    Object.keys(externalCommandConfig).forEach(command => {
      const {
        action,
        paramsNames,
      } = externalCommandConfig[command];

      boundExternalCommandConfig[command] = {
        action: bindActionCreator(action, dispatch),
        paramsNames,
      };
    });

    return {
      boundExternalCommandConfig,
    };
  };

  class ExternalCommandHandler extends React.Component {
    static clearQueryParams(paramsNames, currentUri) {
      const commandQueryParams = paramsNames.concat([PARAM_NAME_EXTERNAL_COMMAND]);
      const newUri = url.removeQueryParams(currentUri, commandQueryParams);

      window.history.replaceState({}, '', newUri);
    }

    componentDidMount() {
      this.checkExternalCommand();
    }

    checkExternalCommand() {
      if (window.location.search.length === 0) {
        return;
      }

      const queryParams = url.parseQuery(window.location.search.slice(1));
      if (!queryParams) {
        return;
      }

      const currentUri = window.location.pathname;

      const externalCommand = queryParams[PARAM_NAME_EXTERNAL_COMMAND];
      if (externalCommand) {
        if (!this.props.boundExternalCommandConfig[externalCommand]) {
          // TODO osigum log an unknown external command
          return;
        }

        const {
          action,
          paramsNames,
        } = this.props.boundExternalCommandConfig[externalCommand];

        ExternalCommandHandler.clearQueryParams(paramsNames, currentUri);

        const params = _pick(queryParams, paramsNames);
        action(params);
      }
    }

    render() {
      return null;
    }
  }

  ExternalCommandHandler.propTypes = propTypes;

  return connect(null, mapDispatchToProps)(ExternalCommandHandler);
};
