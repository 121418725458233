import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__REGISTRATION } from '../../constants/dialogs';


export const actionShowRegistrationDialog = () => (
  actionShowModal(MODAL_DIALOG__REGISTRATION)
);

export const actionHideRegistrationDialog = () => (
  actionHideModal(MODAL_DIALOG__REGISTRATION)
);
