import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';

import { MODAL_DIALOG__CONTACT_US } from '../../constants/dialogs';


export const actionShowContactUsDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__CONTACT_US))
);

export const actionHideContactUsDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__CONTACT_US))
);
