import { combineReducers } from 'redux';

import {
  ACTION_INIT_DATA__REQUEST,
  ACTION_INIT_DATA__SUCCESS,
  ACTION_INIT_DATA__FAIL,
} from '../actionTypes/initData';


const error = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_DATA__REQUEST:
    case ACTION_INIT_DATA__SUCCESS:
      return null;

    case ACTION_INIT_DATA__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};

const allCountries = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_DATA__SUCCESS:
      return action.payload.allCountries;

    default:
      return state;
  }
};

const availableAffiliateProgramTypes = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_DATA__SUCCESS:
      return action.payload.availableAffiliateProgramTypes;

    default:
      return state;
  }
};

const siteId = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_DATA__SUCCESS:
      return action.payload.siteId;

    default:
      return state;
  }
};

const apiDomainWithScheme = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_DATA__SUCCESS:
      return action.payload.apiDomainWithScheme;

    default:
      return state;
  }
};

const landingDomainWithScheme = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_DATA__SUCCESS:
      return action.payload.landingDomainWithScheme;

    default:
      return state;
  }
};

const reCaptchaKey = (state = null, action) => {
  switch (action.type) {
    case ACTION_INIT_DATA__SUCCESS:
      return action.payload.reCaptchaKey;

    default:
      return state;
  }
};

export default combineReducers({
  allCountries,
  availableAffiliateProgramTypes,
  siteId,
  apiDomainWithScheme,
  landingDomainWithScheme,
  error,
  reCaptchaKey,
});
