import { combineReducers } from 'redux';

import resetPasswordError from './resetPasswordError';
import restorePasswordError from './restorePasswordError';
import validateTokenError from './validateTokenError';


export default combineReducers({
  resetPasswordError,
  restorePasswordError,
  validateTokenError,
});
