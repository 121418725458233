import { takeLatest } from 'redux-saga/effects';
import { ACTION_ADD_EXCEPTION } from 'react-redux-app/lib/modules/exception/actionTypes';
import { console, dom } from 'react-redux-app/lib/modules/core/utils';


export default (internalErrorUri, isDev = false) => {
  const handleException = action => {
    console.consoleError(action.payload.exception);
    if (isDev) {
      /* eslint-disable no-alert */
      alert('Unexpected error occurred. See console for details.');
      /* eslint-enable no-alert */
    } else {
      // TODO discuss what to do, i think we should not redirect to some page in case of landing.
      // We can show some popup for example
      // dom.redirect(internalErrorUri);
    }
  };

  return function* saga() {
    yield takeLatest([
      ACTION_ADD_EXCEPTION,
    ], handleException);
  };
};
