export const LANG_IT = 'it';
export const LANG_EN = 'en';
export const LANG_FR = 'fr';
export const LANG_ES = 'es';
export const LANG_DE = 'de';
export const LANG_RU = 'ru';

export const langs = [
  LANG_IT,
  LANG_EN,
  LANG_FR,
  LANG_ES,
  LANG_DE,
  LANG_RU,
];

export const langLangKeys = {
  [LANG_EN]: 'msg.lang.english',
  [LANG_IT]: 'msg.lang.italian',
  [LANG_FR]: 'msg.lang.french',
  [LANG_ES]: 'msg.lang.spanish',
  [LANG_DE]: 'msg.lang.german',
  [LANG_RU]: 'msg.lang.russian',
};
