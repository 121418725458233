import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { LANG_IT } from '../modules/core/lang/constants';

import { apiClient } from '../modules/core/api/ApiClient';
import { apiClientHelper } from './modules/core/api/ApiClientHelper';

import configureStore from './modules/app/configureStore';
import rootReducer from './modules/app/reducers';
import sagas from './modules/app/sagas';

import getApiCriticalErrorHandlers from './modules/app/getApiCriticalErrorHandlers';
import { criticalErrorHandlersStorage } from 'react-redux-app/lib/modules/api/CriticalErrorHandlersStorage';

import { getLangFromRequestUri } from '../modules/core/i18n/utils';

import App from './modules/app/containers/App';


const currentLang = getLangFromRequestUri(window.location.pathname) || LANG_IT;

const reduxStore = configureStore(
  {
    i18n: {
      translations: { [currentLang]: window.LANDING_APP.translations },
      currentLang,
    },
    init: { initData: { reCaptchaKey: window.LANDING_APP.reCaptchaKey } },
  },
  [],
  rootReducer,
  sagas,
  apiClient,
  apiClientHelper
);

criticalErrorHandlersStorage.setErrorHandlers(getApiCriticalErrorHandlers(reduxStore));

createRoot(document.getElementById('appRoot')).render(
  <Provider store={reduxStore} key="provider">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
