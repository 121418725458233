import _escape from 'lodash/escape';

import {
  actionAddTranslations as baseActionAddTranslations,
  actionChangeLang as baseActionChangeLang,
} from 'react-redux-app/lib/modules/i18n/actions';

import { cookie } from 'react-redux-app/lib/modules/core/utils';

import { LANGUAGE_COOKIE, COOKIE_LANGUAGE_EXPIRES } from '../constants';


export const actionAddTranslations = translations => {
  const escapedTranslations = {};
  Object.keys(translations).forEach(lang => {
    escapedTranslations[lang] = {};
    Object.keys(translations[lang]).forEach(langKey => {
      escapedTranslations[lang][langKey] = _escape(translations[lang][langKey]);
    });
  });

  return baseActionAddTranslations(escapedTranslations);
};

export const actionChangeLang = lang => {
  cookie.setCookie(
    LANGUAGE_COOKIE,
    lang,
    {
      expires: COOKIE_LANGUAGE_EXPIRES,
      path: '/',
    }
  );

  return baseActionChangeLang(lang);
};
